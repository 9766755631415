.center-on-page {
  position: absolute;
  top: 49%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.hide {
  visibility: hidden;
}

/*
  Container
*/
.nav-wrapper, .footer-copyright {
  padding: 0px 20px;
}

.page-header {
    height: 75px;
}

.page-footer {
  height: 50px;
  padding: 0px !important;
}

.page-header, .page-footer {
  @include transition(400ms);
  overflow: hidden;
}

.collapsed {
  height: 0px !important;
  overflow: hidden;
}

.brand-logo > img {
  @extend .responsive-img;
  margin-top: 10px;
  height: 48px;
}

shell-app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
}

.btn-flat {
  padding: 0 1rem 0 .6rem;

  i {
    vertical-align: middle;
    margin: 0px 5px;
  }

  span {
    display: inline-block;
    vertical-align: middle;
  }
}

nav {
  .side-nav {
    li * {
      vertical-align: middle;
    }

    i, span {
      display: inline-block;
    }

    span {
      font-size: 1.2em;
      padding-left: 15px;
    }
  }
}

/*
  Login Screen
*/


/*
  Home Screen
*/

.app-panel-container {
  margin: 10px;

  .app-panel {
    padding: 10px;
    display: inline-block;
    overflow: hidden;
    margin: 10px;
    height: 250px;
    width: 250px;

    .app-img-container {
      margin-top: 10px;
      text-align: center;
    }

    .app-title-container {
      margin-top: 25px;
      text-align: center;
    }

    .app-links {
      position: absolute;
      font-size: 1.2em;
      left: 5%;
      bottom: 5%;
    }

    .app-more-info {
      cursor: pointer;
      position: absolute;
      bottom: 5%;
      right: 5%;
    }
  }
}

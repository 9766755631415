@media (min-width: $media-medium-low-breakpoint) {
  button.google-sign-in {
    border: 0px;
    height: 46px;
    width: 184px;
    background: url('../../shared/images/btn_google_signin_sprite.png') no-repeat 0 top;
  }
}

@media (max-width: $media-small-high-breakpoint) {
  button.google-sign-in {
    border: 0px;
    height: 46px;
    width: 95px;
    background: url('../../shared/images/btn_google_signin_sprite_small.png') no-repeat 0 top;
  }
}

button.google-sign-in:hover {
  background-position-y: -46px;
}

button.google-sign-in:active {
  background-position-y: -92px;
}

button.google-sign-in:disabled {
  background-position-y: -138px;
}
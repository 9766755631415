/* valid color */
.input-field .ng-valid {
  border-bottom: 1px solid $success-color;
  box-shadow: 0 1px 0 0 $success-color;
}

/* invalid color */
.input-field .ng-invalid {
  border-bottom: 1px solid $input-error-color;
  box-shadow: 0 1px 0 0 $input-error-color;
}
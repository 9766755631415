@media only screen and (min-width: 993px) {
  #toast-container {
    top: auto;
    right: 5%;
    bottom: 7%;
  }
}

@media only screen and (max-width: 600px) {
  #toast-container {
    bottom: 0%;
  }
}

.alert-toast {
  background-color: $error-color;
}